<template>
  <div>
    <el-card>
      <div class="flex-def" style="padding: 2rem">
        <div class="panel-shadow poster-con"
             :style="`background-image:url(${bgShowUrl})`">
          <div v-drag="that" data-pre="qrcode" :draggable="false" @dragover="qrcodeDrag" :style="qrcodeStyle"
               class="y-pointer flex-def flex-zCenter flex-cCenter poster-qrcode">二维码
          </div>
          <div v-drag="that" data-pre="pic" :style="picStyle"
               class="y-pointer flex-def flex-zCenter flex-cCenter poster-qrcode">画册图
          </div>
<!--          <div v-drag="that" data-pre="invite_code" :style="invite_codeStyle"-->
<!--               class="y-pointer flex-def flex-zCenter flex-cCenter poster-invite_code">邀请码-->
<!--          </div>-->
        </div>
        <div style="margin-left: 2rem">
          <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="海报背景图片">
              <span style="font-size: 9px;color: #888888">海报尺寸640*1008</span>
              <y_upload_img v-model="form.bg"></y_upload_img>
            </el-form-item>
            <el-form-item label="二维码大小">
              <el-input-number v-model="form.qrcode_size"></el-input-number>
            </el-form-item>
            <el-form-item label="画册图高度">
              <el-input-number v-model="form.pic_height"></el-input-number>
            </el-form-item>
            <el-form-item label="画册图宽度">
              <el-input-number v-model="form.pic_width"></el-input-number>
            </el-form-item>
<!--            <el-form-item label="邀请码字号">-->
<!--              <el-input v-model="form.invite_code_size"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="邀请码颜色">-->
<!--              <y_choose_color v-model="form.invite_code_color"></y_choose_color>-->
<!--            </el-form-item>-->
            <el-form-item>
              <el-button @click="formSubmit" type="primary">保存</el-button>
              <el-button @click="clear">清空缓存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
// import y_choose_color from "@/components/y-form/y_choose_color";

export default {
  name: "poster",
  components: {
    y_upload_img,
    // y_choose_color
  },
  directives: {
    drag(el, App) {
      let oDiv = el;
      document.onselectstart = function () {
        return false;
      };
      oDiv.onmousedown = function (e) {
        let disX = e.clientX - oDiv.offsetLeft;
        let disY = e.clientY - oDiv.offsetTop;
        document.onmousemove = function (e) {
          let l = e.clientX - disX;
          let t = e.clientY - disY;
          if (l < 0) l = 0;
          if (App.value.form[el.dataset.pre + '_size'] / 2 + l > 320) l = 320 - (App.value.form[el.dataset.pre + '_size'] / 2);
          if (t < 0) t = 0;
          if (App.value.form[el.dataset.pre + '_size'] / 2 + t > 504) t = 504 - (App.value.form[el.dataset.pre + '_size'] / 2);
          oDiv.style.left = l + "px";
          oDiv.style.top = t + "px";
          App.value.form[el.dataset.pre + '_left'] = l * 2
          App.value.form[el.dataset.pre + '_top'] = t * 2
        }
        document.onmouseup = function () {
          document.onmousemove = null;
          document.onmouseup = null;
        };
        return false;
      };
    }
  },
  data() {
    return {
      that: this,
      form: {
        bg: "",
        qrcode_left: 0,
        qrcode_top: 0,
        qrcode_size: 100,

        pic_top:200,
        pic_left:0,
        pic_height:100,
        pic_width:100,
        // invite_code_left: 0,
        // invite_code_top: 350,
        // invite_code_size: 28,
        // invite_code_color: "#000000"
      }
    }
  },
  mounted() {
    this.load();
  },
  computed: {
    bgShowUrl(){
      return  this.$u.toMedia(this.form.bg);
    },
    qrcodeStyle() {
      let left = this.form.qrcode_left / 2;
      let top = this.form.qrcode_top / 2;
      let size = this.form.qrcode_size / 2;
      if (!size) return `display:none`;
      return `left:${left}px;top:${top}px;height:${size}px;width:${size}px`;
    },
    picStyle(){
      return {
        left: this.form.pic_left/2 + "px",
        top: this.form.pic_top/2 + "px",
        width: this.form.pic_width/2 + "px",
        height: this.form.pic_height/2 + "px",
      }
    }
    // invite_codeStyle() {
    //   let left = this.form.invite_code_left / 2;
    //   let top = this.form.invite_code_top / 2;
    //   let size = this.form.invite_code_size / 2;
    //   let color = this.form.invite_code_color;
    //   if (!size) return `display:none`;
    //   return `left:${left}px;top:${top}px;font-size:${size}px;color:${color}`;
    // }
  },
  methods: {
    clear(){
      this.$u.api.album.posterClear().then(()=>{
        this.$message.success("操作成功")
      })
    },
    load(){
      this.$u.api.album.poster().then(res=>{
        this.form = res;
      })
    },
    formSubmit() {
      this.form.qrcode_size = parseInt(this.form.qrcode_size);
      // this.form.invite_code_size = parseInt(this.form.invite_code_size);
      this.$u.api.album.posterEdit(this.form).then(() => {
        this.$message.success("操作成功");
      })
    },
    qrcodeDrag(e) {
      e.preventDefault();
      this.form.qrcode_top = e.offsetX * 2;
      this.form.qrcode_left = e.offsetY * 2;
      console.log(this.form, e.offsetX * 2);
    }
  }
}
</script>

<style scoped>
.poster-avatar,
.poster-qrcode,
.poster-invite_code {
  position: absolute;
  white-space: nowrap;
}

.poster-avatar,
.poster-qrcode {
  color: #f8f8f8;
  background-color: #5D60F6
}

.poster-avatar {
  height: 50px;
  width: 50px;
}

.poster-qrcode {
  height: 100px;
  width: 100px;
}

.poster-con {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  width: 320px;
  height: 504px;
  overflow: hidden;
}
</style>